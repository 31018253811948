import React from 'react';

const Links = () => {
  return (
    <React.Fragment>
      <h3>Don't forget!</h3>
      <ul className="github-links">
        <li>
          <a href="https://github.com/AGontcharov/Dialog-System-Compiler">
            Dialog System Compiler
          </a>
        </li>
        <li>
          <a href="https://github.com/AGontcharov/Wildebeest-2-Player-Chess-AI">
            Wildebeest 2 Player Chess AI
          </a>
        </li>
        <li>
          <a href="https://github.com/AGontcharov/Artificial-Neural-Network">
            Artificial Neural Network
          </a>
        </li>
        <li>
          <a href="https://github.com/AGontcharov/Genetic-Algorithm">
            Genetic Algorithms
          </a>
        </li>
        <li>
          <a href="https://github.com/AGontcharov/budget-tracker">Budget Tracker</a>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Links;
